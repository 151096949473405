import { ErrorPayload, NotFoundReason } from '../../../api';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ErrorProps {
  error: ErrorPayload<NotFoundReason>;
  clearError: () => void;
}

export const NotFound = (props: ErrorProps) => {
  const navigate = useNavigate();
  const state = props.error;

  const navigationButton = (path: any, label: string) => (
    <button
      onClick={() => {
        props.clearError();
        navigate(path);
      }}
      type='button'
      className='px-4 py-2 rounded-full bg-slate-200 text-violet-950 text-sm font-semibold'
    >
      {label}
    </button>
  );

  return (
    <div className='h-screen w-full flex justify-center items-center'>
      <div className='p-4 flex flex-col gap-y-4 text-zinc-900 items-center border rounded-2xl shadow text-center'>
        <FontAwesomeIcon className='size-10' icon={regular('file-slash')} />
        <div className='tracking-wide uppercase text-xs'>{state.requestSid}</div>
        {(() => {
          switch (state.errorCode) {
            case NotFoundReason.User:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='max-w-72'>This user might have been deleted from this workspace</div>
                  {navigationButton(-1, 'Back')}
                </>
              );
            case NotFoundReason.Product:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='max-w-72'>This product might have been deleted from this workspace</div>
                  <div className='flex items-center gap-x-6'>
                    {navigationButton(-1, 'Back')}
                    {navigationButton('/', 'Home')}
                  </div>
                </>
              );
            case NotFoundReason.Model:
              return (
                <>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='max-w-72'>This model might have been deleted from this workspace</div>
                  <div className='flex items-center gap-x-6'>
                    {navigationButton(-1, 'Back')}
                    {navigationButton('/', 'Home')}
                  </div>
                </>
              );

            default:
              return (
                <>
                  <div>{props.error.message}</div>
                  <div className='text-lg font-semibold'>Sorry, we couldn’t find what you were looking for</div>
                  <div className='flex items-center gap-x-6'>
                    {navigationButton(-1, 'Back')}
                    {navigationButton('/', 'Home')}
                  </div>
                </>
              );
          }
        })()}
      </div>
    </div>
  );
};
