import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniq from 'lodash/uniq';
import { Fragment, RefObject, SetStateAction, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { IngredientType, Mapping, MappingProduct, Tag, arrayToStringParam, getMappingProducts } from '../../../../api';
import { Filters } from '../../../../components/Filters';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { ModalApi } from '../../../../components/ModalV3';
import { simplify } from '../../shared';
import { ManageEntity, resolvedMappings, unresolvedMappings } from '../Overview';
import { ProductsListModal } from './components/ProductsListModal';
import { TagBadge } from './components/TagBadge';
import { getInitialFormData } from './dataModel';
import { SkeletonLoader } from '../SkeletonLoader';

interface Props {
  mainListHeaderRef: RefObject<HTMLDivElement>;
  selectedTab: 'resolved' | 'unresolved';
  onTabSelect: (value: boolean) => void;
  mappingProducts: { list: MappingProduct[]; mappingId: string; searchString: string };
  setMappingProducts: (data: SetStateAction<{ list: MappingProduct[]; mappingId: string; searchString: string }>) => void;
  mappingsData: ManageEntity<Mapping[]>;
  setMappingsData: (data: SetStateAction<ManageEntity<Mapping[]>>) => void;
  fetchNextMappingsPage: () => void;
  expanded: string[];
  handleExpandedState: (value: string) => void;
  selectedCategory: string;
  mainListHeight?: number;
  loading: boolean;
}

export const ListView = (props: Props) => {
  const [collapsed, setCollapsed] = useState<Array<string>>([]);

  const filterFields = [
    {
      label: 'State',
      items: [
        { label: 'All', value: props.selectedTab === 'resolved' ? resolvedMappings : unresolvedMappings },
        ...(() => {
          return props.selectedTab === 'resolved'
            ? [
                { label: 'Exact match', value: Tag.ExactMatch },
                { label: 'User edited', value: Tag.UserInput },
              ]
            : [
                { label: 'Unmatched', value: Tag.Unmatched },
                { label: 'Best match', value: Tag.BestMatch },
              ];
        })(),
      ],
      field: 'state',
      value: props.mappingsData.searchParams.state ?? '',
      disabled: false,
    },
  ];

  const handleCollapse = (id: string) => {
    if (collapsed.includes(id)) {
      setCollapsed(collapsed.filter((item) => item !== id));
    } else {
      setCollapsed([...collapsed, id]);
    }
  };

  return (
    <>
      <div ref={props.mainListHeaderRef}>
        <div className='h-20 flex items-center justify-between border-b border-zinc-200 bg-white px-6'>
          <div className='font-semibold text-lg'>Mappings</div>
          <div className='flex gap-x-3 text-xs font-semibold text-zinc-400 mt-2'>
            <button
              onClick={() => props.onTabSelect(false)}
              className={cn('uppercase', { 'text-dark': props.selectedTab === 'unresolved' })}
            >
              <div
                className={cn(
                  'flex gap-x-1 items-center py-1 border-b-2',
                  props.selectedTab === 'unresolved' ? 'border-brand' : 'border-transparent',
                )}
              >
                <div>to be reviewed</div>
                <div
                  title={props.mappingsData.counters?.reviewNeeded.toString()}
                  className='flex items-center justify-center px-1 rounded-full text-brand bg-slate-100 text-xs'
                >
                  {props.mappingsData.counters && props.mappingsData.counters.reviewNeeded > 999
                    ? '999+'
                    : props.mappingsData.counters?.reviewNeeded}
                </div>
              </div>
            </button>
            <div className='h-5 w-px bg-zinc-300' />
            <button
              onClick={() => props.onTabSelect(true)}
              className={cn('uppercase flex flex-col gap-y-0.5', { 'text-dark': props.selectedTab === 'resolved' })}
            >
              <div
                className={cn(
                  'flex gap-x-1 items-center py-1 border-b-2',
                  props.selectedTab === 'resolved' ? 'border-brand' : 'border-transparent',
                )}
              >
                <div>resolved</div>
                <div
                  title={props.mappingsData.counters?.resolved.toString()}
                  className='flex items-center justify-center px-1 rounded-full bg-emerald-50 text-emerald-700 text-xs'
                >
                  {props.mappingsData.counters && props.mappingsData.counters.resolved > 999
                    ? '999+'
                    : props.mappingsData.counters?.resolved}
                </div>
              </div>
            </button>
          </div>

          <div className='flex gap-x-3'>
            {props.mappingsData.counters && props.mappingsData.counters.unmatched > 0 && (
              <button
                onClick={() => {
                  props.onTabSelect(false);
                  props.setMappingsData((current) => ({
                    ...current,
                    searchParams: {
                      ...current.searchParams,
                      state: arrayToStringParam([Tag.Unmatched]),
                    },
                  }));
                }}
                className='flex items-center gap-x-2 px-3 bg-red-500 text-white font-semibold rounded-lg'
              >
                <div title={props.mappingsData.counters.unmatched.toString()}>{simplify(props.mappingsData.counters.unmatched)}</div>
                <div>Unmatched</div>
              </button>
            )}

            <div className='flex-1 flex items-center justify-end gap-4'>
              <Filters<string, any>
                count={
                  [props.mappingsData.searchParams.state].filter((item) =>
                    props.selectedTab === 'resolved'
                      ? resolvedMappings.split('|').includes(item!)
                      : unresolvedMappings.split('|').includes(item!),
                  ).length
                }
                filterFields={filterFields}
                searchParams={props.mappingsData.searchParams}
                setSearchParams={(setSearchParams) => {
                  props.setMappingsData((current) => ({
                    ...current,
                    searchParams: {
                      ...setSearchParams(props.mappingsData.searchParams),
                    },
                  }));
                }}
              />
            </div>
            <div className='flex items-center relative'>
              <input
                type='search'
                className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                autoFocus
                placeholder='Find…'
                value={props.mappingsData.searchString}
                onChange={(event) => props.setMappingsData({ ...props.mappingsData, searchString: event.target.value })}
              />
              <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
            </div>
          </div>
        </div>

        {props.selectedTab === 'unresolved' && (
          <div className='flex flex-col'>
            <button
              type='button'
              onClick={() => props.handleExpandedState('To be reviewed mappings')}
              className='flex justify-between items-center hover:text-brandDark px-6 py-4'
            >
              <div className='flex items-center gap-2 text-base'>
                <FontAwesomeIcon className='text-brand' icon={regular('info-circle')} />
                <div className='font-semibold'>To be reviewed mappings</div>
              </div>
              <FontAwesomeIcon
                className={cn('h-4 aspect-square', { '-rotate-90': !props.expanded.includes('To be reviewed mappings') })}
                icon={solid('chevron-down')}
              />
            </button>
            {props.expanded.includes('To be reviewed mappings') && (
              <div className='px-6 pb-4'>
                You will find below all automatic mappings that were made when importing your data as well as any data we could not
                successfully map to something in our database. Where we couldn’t map, we created placeholders in all relevant products.
                Changing anything here will make sure all products where the same name was found will use the new mapping instead.
                Alternatively, you can change anything at the product level.
              </div>
            )}
          </div>
        )}

        {props.selectedTab === 'resolved' && (
          <div className='flex flex-col'>
            <button
              type='button'
              onClick={() => props.handleExpandedState('Resolved mappings')}
              className='flex justify-between items-center hover:text-brandDark px-6 py-4'
            >
              <div className='flex items-center gap-2 text-base'>
                <FontAwesomeIcon className='text-brand' icon={regular('info-circle')} />
                <div className='font-semibold'>Resolved mappings</div>
              </div>
              <FontAwesomeIcon
                className={cn('h-4 aspect-square', { '-rotate-90': !props.expanded.includes('Resolved mappings') })}
                icon={solid('chevron-down')}
              />
            </button>
            {props.expanded.includes('Resolved mappings') && (
              <div className='px-6 pb-4'>
                Below are the mappings which we deemed to be ‘exact matches’ and as such not requiring your review for validation of the
                results. In addition to those, you will find all mappings manually edited by yourself tagged as ‘user input’.
              </div>
            )}
          </div>
        )}
      </div>

      {props.mainListHeight &&
        (props.loading ? (
          <SkeletonLoader height={props.mainListHeight} />
        ) : (
          <InfiniteScroll
            height={props.mainListHeight}
            dataLength={props.mappingsData.list.length}
            next={props.fetchNextMappingsPage}
            hasMore={props.mappingsData.nextPageToken !== ''}
            loader={
              <div className='py-3 text-center'>
                <FontAwesomeIcon size='2x' pulse icon={duotone('loader')} />
              </div>
            }
          >
            <table className='table-fixed w-full'>
              <thead>
                <tr className='h-12 bg-white border-y sticky z-[1] top-0'>
                  <th className='pl-6 pr-0.5 py-3 w-60'>Extracted data</th>
                  <th className='px-0.5'>ID</th>
                  <th className='px-0.5'>Used in</th>
                  <th className='text-[10px] uppercase text-zinc-500 text-center px-0.5 w-16'>
                    <div className='mt-0.5'>mapped to</div>
                  </th>
                  {props.selectedCategory === 'all' && <th className='px-0.5 pl-1.5'>Group</th>}
                  <th className='px-0.5'>Name</th>
                  <th className='px-0.5'>Type</th>
                  <th className='pl-2.5 w-40'>State</th>
                  <th className='w-10 pr-3' />
                </tr>
              </thead>
              <tbody>
                {props.mappingsData.list.map((mapping, index) => (
                  <Fragment key={index}>
                    <Row
                      index={index}
                      mapping={mapping}
                      selectedCategory={props.selectedCategory}
                      mappingProducts={props.mappingProducts}
                      setMappingProducts={props.setMappingProducts}
                      collapsed={collapsed}
                      handleCollapse={handleCollapse}
                    />
                  </Fragment>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        ))}
    </>
  );
};

interface RowProps {
  index: number;
  mapping: Mapping;
  selectedCategory: string;
  mappingProducts: { list: MappingProduct[]; mappingId: string; searchString: string };
  setMappingProducts: (data: SetStateAction<{ list: MappingProduct[]; mappingId: string; searchString: string }>) => void;
  collapsed: Array<string>;
  handleCollapse: (value: string) => void;
}

const Row = (props: RowProps) => {
  const modalRef = useRef<ModalApi>(null);
  const navigate = useNavigate();
  const mapping = props.mapping;

  return (
    <tr
      onClick={() => navigate(`../mappings/${props.selectedCategory}/${mapping.workspaceMappingId}`)}
      className={cn(
        'border-b border-zinc-200 hover:bg-slate-200 hover:cursor-pointer',
        mapping.state === Tag.Unmatched ? 'bg-red-50' : props.index % 2 ? 'bg-white' : 'bg-slate-50',
      )}
    >
      <td title={mapping.extractedData} className='pl-6 pr-0.5 py-3 truncate'>
        {mapping.extractedData}
      </td>
      <td title={mapping.internalId} className='truncate px-0.5'>
        {mapping.internalId}
      </td>
      <td className='px-0.5'>
        <div onClick={(e) => e.stopPropagation()} className='flex items-center'>
          <ProductsListModal
            mapping={mapping}
            mappingProducts={props.mappingProducts}
            setMappingProducts={props.setMappingProducts}
            modalRef={modalRef}
          >
            <button
              disabled={mapping.usedIn === 0}
              className='flex items-center rounded-full border p-0.5 pr-1.5 gap-1 bg-white'
              onClick={(e) => {
                e.stopPropagation();
                getMappingProducts(mapping.workspaceMappingId, { contains: '' }).call({
                  ok: (data) => {
                    props.setMappingProducts((current) => ({
                      ...current,
                      mappingId: mapping.workspaceMappingId,
                      list: data.products,
                    }));
                    modalRef.current?.open();
                  },
                });
              }}
            >
              <div
                title={mapping.usedIn.toString()}
                className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-xs'
              >
                {mapping.usedIn > 99 ? '99+' : mapping.usedIn}
              </div>
              <div className='mb-0.5'>products</div>
            </button>
          </ProductsListModal>
        </div>
      </td>
      <td className='text-center px-0.5'>
        <FontAwesomeIcon icon={light('right-to-line')} />
      </td>

      {props.selectedCategory === 'all' && <td className='pl-1.5'>{mapping.type}</td>}

      {(() => {
        if (mapping.type === 'ingredient') {
          const names = mapping.mappedData.items
            .filter(({ ingredient }) => ingredient !== undefined)
            .map(({ ingredient }) => ingredient!.name)
            .join(', ');
          return (
            <td title={names} className='truncate px-0.5'>
              {names}
            </td>
          );
        }

        if (mapping.type === 'packaging') {
          const names = mapping.mappedData.items
            .filter(({ packaging }) => packaging)
            .map((item) => item.packaging?.name)
            .join(', ');

          return (
            <td title={names} className='truncate px-0.5'>
              {names}
            </td>
          );
        }
      })()}

      {(() => {
        if (mapping.type === 'ingredient') {
          const ingredientsTypes = mapping.mappedData.items
            .filter(({ ingredient }) => ingredient)
            .map(({ ingredient }) => {
              return {
                [IngredientType.Generic]: 'Generic',
                [IngredientType.IntermediateProduct]: 'Intermediate product',
                [IngredientType.Consumer]: 'Consumer ingredient',
                [IngredientType.GroupProduct]: 'Group product',
                [IngredientType.PrivateProduct]: 'Private product',
              }[ingredient!.type];
            })
            .join(', ');
          return (
            <td title={ingredientsTypes} className='truncate px-0.5'>
              {ingredientsTypes}
            </td>
          );
        }

        if (mapping.type === 'packaging') {
          const names = mapping.mappedData.items
            .filter(({ materials }) => materials)
            .flatMap(({ materials }) => materials?.flatMap(({ subType }) => subType).flatMap(({ name }) => name))
            .map((item) => item);

          const isDefaultMaterial = mapping.mappedData.items.find((item) => item.materials !== undefined)?.qualifier === Tag.Default;

          return (() => {
            if (names.length === 0) {
              return <td />;
            }

            if (names.length === 1) {
              return (
                <td title={names[0]} className='truncate px-0.5'>
                  {isDefaultMaterial && 'Default: '}
                  {names[0]}
                </td>
              );
            }

            if (names.length > 1) {
              return (
                <td>
                  <button title={names.toString()} className='flex items-center rounded-full border p-0.5 pr-1.5 gap-1 bg-white'>
                    <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-xs'>
                      {names.length}
                    </div>
                    <div className='mb-0.5'>materials</div>
                  </button>
                </td>
              );
            }
          })();
        }
      })()}
      <td className='pl-2.5'>
        <div className='flex items-center gap-1'>
          {(() => {
            const innerUniqQualifiers = uniq(
              (getInitialFormData(mapping).mappedData.items as { qualifier?: Tag }[]).flatMap(({ qualifier }) => qualifier),
            ).filter((qualifier) => qualifier !== undefined);

            return (
              <Fragment>
                <TagBadge state={mapping.state} />
                {innerUniqQualifiers.length > 0 &&
                  (!props.collapsed.includes(mapping.workspaceMappingId) ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleCollapse(mapping.workspaceMappingId);
                      }}
                      className='flex leading-none whitespace-nowrap px-1.5 py-1 rounded bg-slate-100 text-[10px] font-semibold'
                    >
                      {`+${innerUniqQualifiers.length} other${innerUniqQualifiers.length > 1 ? 's' : ''}`}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleCollapse(mapping.workspaceMappingId);
                      }}
                      className='flex items-center justify-center flex-wrap gap-0.5'
                    >
                      {innerUniqQualifiers.map((qualifier, index) => (
                        <div key={index}>
                          <TagBadge state={qualifier!} />
                        </div>
                      ))}
                    </button>
                  ))}
              </Fragment>
            );
          })()}
        </div>
      </td>
      <td className='px-0.5'>
        <button
          onClick={() => navigate(`../mappings/${props.selectedCategory}/${mapping.workspaceMappingId}`)}
          className='hover:text-brand rounded-lg'
        >
          <FontAwesomeIcon className='h-4 aspect-square' icon={light('pencil')} />
        </button>
      </td>
    </tr>
  );
};
