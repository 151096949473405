import { Route, Routes, useNavigate, useParams } from 'react-router';
import { setSelectedWorkspaceId } from '../../../api';
import { useEffect } from 'react';

export const Workspaces = () => (
  <Routes>
    <Route path=':workspaceId/*' element={<Workspace />} />
  </Routes>
);

const Workspace = () => {
  const navigate = useNavigate();
  const params = useParams<{ workspaceId: string; ['*']: string }>();

  useEffect(() => {
    setSelectedWorkspaceId(params.workspaceId!);
    navigate(`../../${params['*']!}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return <></>;
};
