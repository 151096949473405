import cn from 'classnames';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { Account } from './Account';
import { Customers } from './Customers';
import { Prototype } from './Prototype';
import { Faq } from './Faq';
import { Help } from './Help';
import { Home } from './Home';
import { Manage } from './Manage';
import { Manufacturing } from './Manufacturing';
import { Methodology } from './Methodology';
import { Navigation } from './Navigation';
import { Products } from './Products';
import { Workspace } from './Workspace';
import { Errors } from './Errors';
import { LimitsProvider } from '../../hooks/useLimits';
import { ListsProvider } from '../../hooks/useLists';
import { ProfileProvider } from '../../hooks/useProfile';
import { Dashboard } from './Dashboard';
import { NewBaseline } from './Manufacturing/Baseline/NewBaseline';
import { NewHistorical } from './Manufacturing/Historicals/NewHistorical';
import { NewForecast } from './Manufacturing/Forecasts/NewForecast';
import { Workspaces } from './Workspaces';
import { ModalApi, ModalV3 } from '../../components/ModalV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';
import { clearAuthentication, ErrorPayload, NotFoundReason } from '../../api';
import { forwardRef, RefObject, useEffect, useRef, useState } from 'react';

export const AppRoutes = (id1?: string, id2?: string) => ({
  products: '/products',
  report: {
    historicalList: '/manufacturing/historical-reports',
    newHistorical: '/manufacturing/historical-reports/new',
    historicalOverview: `/manufacturing/historical-reports/${id1}/report/production/overview`,
    newBaseline: '/manufacturing/baseline/new',
    newForecast: '/manufacturing/forecasts/new',
    forecastOverview: `/manufacturing/forecasts/${id1}/report/production/overview`,
    baselineOverview: `/manufacturing/baseline/production/overview`,
    productOverview: `/products/${id1}/report/production/overview`,
    modelOverview: `/products/${id1}/models/${id2}/report/overview`,
  },
});

const timeout = 1_000 * 60 * 30;
const promptBeforeIdle = 1_000 * 60 * 3;

interface Props {
  error?: ErrorPayload<NotFoundReason>;
  clearError?: () => void;
}

export const Main = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const modalRef = useRef<ModalApi>(null);

  const onIdle = () => {
    clearAuthentication();
    navigate('/');
  };

  return (
    <IdleTimerProvider
      crossTab
      throttle={500}
      onIdle={onIdle}
      timeout={timeout}
      promptBeforeIdle={promptBeforeIdle}
      onPrompt={() => modalRef.current!.open()}
    >
      <Prompt ref={modalRef} />
      <LimitsProvider>
        <ListsProvider>
          <ProfileProvider>
            {props.error ? (
              <Errors error={props.error} clearError={props.clearError!} />
            ) : (
              <div
                className={cn('w-full flex flex-col justify-between items-center min-h-screen', {
                  'bg-slate-50': ['/dashboard', '/prototype'].includes(location.pathname),
                  'gap-10': !['/dashboard', '/prototype'].includes(location.pathname),
                })}
              >
                <Navigation />
                <div className='w-full h-full max-w-screen-xl px-6'>
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/customers/*' element={<Customers />} />
                    <Route path='/methodology/*' element={<Methodology />} />
                    <Route path='/faq/*' element={<Faq />} />
                    <Route path='/help/*' element={<Help />} />
                    <Route path='/account/*' element={<Account />} />
                    <Route path='/workspaces/*' element={<Workspaces />} />
                    <Route path='/workspace/*' element={<Workspace />} />
                    <Route path='/prototype/*' element={<Prototype />} />
                    <Route path='/dashboard/*' element={<Dashboard />} />
                    <Route path='/products/*' element={<Products />} />
                    <Route path={AppRoutes().report.newForecast} element={<NewForecast />} />
                    <Route path={AppRoutes().report.newHistorical} element={<NewHistorical />} />
                    <Route path={AppRoutes().report.newBaseline} element={<NewBaseline />} />
                    <Route path='/manufacturing/*' element={<Manufacturing />} />
                    <Route path='/manage/*' element={<Manage />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                  </Routes>
                </div>
              </div>
            )}
          </ProfileProvider>
        </ListsProvider>
      </LimitsProvider>
    </IdleTimerProvider>
  );
};

const Prompt = forwardRef<ModalApi, object>((_, ref) => {
  const [remaining, setRemaining] = useState<number>(0);

  const { activate, getRemainingTime, pause } = useIdleTimerContext();

  useEffect(() => {
    if (process.env.REACT_APP_LOCAL_IMPACT_SERVICE) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (remaining * 1000 > promptBeforeIdle) {
      (ref as RefObject<ModalApi>).current?.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remaining]);

  return (
    <>
      <ModalV3
        size='narrow-'
        ref={ref}
        hideCancel
        preventDismiss
        onConfirm={activate}
        confirmLabel='Stay logged in'
        title={
          <div className='flex items-center gap-x-2'>
            <div className='flex bg-amber-50 border p-1 rounded-lg'>
              <FontAwesomeIcon icon={regular('exclamation-triangle')} className='text-amber-400' />
            </div>
            <div className='text-base text-black'>Your session is about to expire</div>
          </div>
        }
        body={
          <div className='-mt-6 text-base text-black'>
            Your session will expire in:{' '}
            <span className='font-semibold'>
              {(() => {
                const minutes = Math.floor(remaining / 60);
                const remainingSeconds = remaining % 60;
                return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
              })()}
            </span>
          </div>
        }
      />
    </>
  );
});
