import {
  AgreementType,
  buildQueryString,
  Entity,
  FacilityType,
  IngredientType,
  Pagination,
  ProductType,
  request,
  StaticEntity,
  Supplier,
  Tag,
} from './index';

export enum OwnershipStatusType {
  ThirdParty = 'third_party',
  Owned = 'owned',
  Rented = 'rented',
}

export interface Coordinates {
  lon: number;
  lat: number;
}

export interface Address {
  text: string;
  coordinates: Coordinates;
}

export interface OwnershipStatus extends StaticEntity<OwnershipStatusType> {
  agreement: StaticEntity<AgreementType>;
  default: boolean;
}

export interface Facility extends Entity {
  alias: string;
  type: FacilityType;
  location: Entity;
  owner?: Supplier;
  address?: Address;
  ownershipStatus?: OwnershipStatus;
  updatedAt: string;
  usedInProducts: number;
}

export interface ConsumptionLocation extends Entity {
  alias: string;
  zip: string;
  location: Entity;
}

export interface LocationAddress {
  countryId: string;
  address: string;
  coordinates: Coordinates;
}

export interface MappingProduct {
  id: string;
  name: string;
  type: ProductType;
  netAmount: string;
  skuId: string;
  impactPoints?: number;
}

export interface MappedIngredient {
  id: string;
  name: string;
  type: IngredientType;
  placeholder: boolean;
}

export interface MappedPackaging {
  id: string;
  name: string;
  placeholder: boolean;
}

interface Material {
  compositionPercent: number;
  id: string;
  materialId: string;
  name: string;
  placeholder: false;
  subType: {
    id: string;
    name: string;
    placeholder: false;
  };
}

export interface Conservation {
  id: string;
  name: string;
}

export interface MappedItem {
  ingredient?: MappedIngredient;
  conservation?: Conservation | null;
  packaging?: MappedPackaging;
  materials?: Material[];
  qualifier: Tag;
}

interface MappedData {
  items: MappedItem[];
}

export interface Mapping {
  workspaceMappingId: string;
  type: 'ingredient' | 'packaging';
  internalId: string;
  extractedData: string;
  extractedCategory?: string; // only for packaging
  mappedData: MappedData;
  state: Tag;
  usedIn: number;
  createdAt: string;
  editable: boolean;
}

export interface Counters {
  resolved: number;
  reviewNeeded: number;
  unmatched: number;
}

export interface MappingsPagination<T> extends Pagination {
  counters: Counters;
  mappings: T;
}

export const resolveAddress = ({ address, countryId }: { address: string; countryId: string }) =>
  request<{ locations: LocationAddress[] }>('GET', `/v3/locations${buildQueryString({ address, countryId })}`);

export const getFacilities = (payload: { [key: string]: string | number | boolean | undefined }) =>
  request<{ facilities: Facility[]; nextPageToken: string; totalResults: number }>('GET', `/v3/facilities${buildQueryString(payload)}`);
export const getFacility = (id: string) => request<Facility>('GET', `/v3/facilities/${id}`);
export const createFacility = (payload: Facility) => request<Facility>('POST', '/v3/facilities', { body: payload });
export const updateFacility = (payload: Facility) => request<Facility>('PUT', `/v3/facilities/${payload.id}`, { body: payload });
export const deleteFacility = (id: string) => request<{ errorCode?: string } | undefined>('DELETE', `/v3/facilities/${id}`);

export const getSuppliers = (payload: { [key: string]: string | number | boolean | undefined }) =>
  request<{ suppliers: Supplier[]; nextPageToken: string; totalResults: number }>('GET', `/v3/suppliers${buildQueryString(payload)}`);
export const getSupplier = (id: string) => request<Supplier>('GET', `/v3/suppliers/${id}`);
export const createSupplier = (payload: Supplier) => request<Supplier>('POST', '/v3/suppliers', { body: payload });
export const updateSupplier = (payload: Supplier) => request<Supplier>('PUT', `/v3/suppliers/${payload.id}`, { body: payload });
export const deleteSupplier = (id: string) => request<{ errorCode?: string } | undefined>('DELETE', `/v3/suppliers/${id}`);

export const getWorkspaceMappings = (payload: { [key: string]: string | number | boolean | undefined }) =>
  request<MappingsPagination<Mapping[]>>('GET', `/v3/workspace-mappings${buildQueryString(payload)}`);

export const getMapping = (mappingId: string) => request<Mapping>('GET', `/v3/workspace-mappings/${mappingId}`);

export const updateMapping = (mappingId: string, payload: Mapping) =>
  request<Mapping>('PUT', `/v3/workspace-mappings/${mappingId}`, { body: payload });

export const getMappingProducts = (mappingId: string, payload: { contains: string }) =>
  request<{ products: MappingProduct[] }>('GET', `/v3/workspace-mappings/${mappingId}/products${buildQueryString(payload)}`);
