import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Formik, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useEffect, useReducer, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useBlocker, useParams } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Facility,
  FacilityType,
  OwnershipStatus,
  OwnershipStatusType,
  createFacility,
  deleteFacility,
  facilityOptions,
  getFacility,
  updateFacility,
} from '../../../../api';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { UnsavedChangesModalWarning } from '../../../../components/UnsavedChangesModalWarning';
import { Modal } from '../../../../components/Modal';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { InfiniteScrollProductListModal } from '../InfiniteScrollProductListModal';
import { ManageEntity } from '../Overview';
import { StatusBadge } from '../StatusBadge';
import { AsyncFilters } from './AsyncFilters';
import { EditFacilityForm } from './EditFacilityForm';
import { SkeletonLoader } from '../SkeletonLoader';

interface Props {
  title?: string;
  fetchNextLocationsPage: () => void;
  loading: boolean;
  reload: () => void;
  selectedCategory: string;
  facilityData: ManageEntity<Facility[]>;
  setFacilityData: (v: SetStateAction<ManageEntity<Facility[]>>) => void;
}

export const facilityValidationSchema = yup.object().shape({
  type: yup.string().required(),
  name: yup.string().required(),

  address: yup.object().when('type', {
    is: (id: FacilityType) => id === FacilityType.Consumption,
    then: yup.object().nullable().notRequired(),
    otherwise: yup.object().required(),
  }),
  location: yup.object().required(),
  owner: yup.object().when('type', {
    is: (id: FacilityType) => id === FacilityType.Consumption,
    then: (schema) => schema.nullable().notRequired(),
    otherwise: yup.object().required(),
  }),
  ownershipStatus: yup
    .object()
    .default(null)
    .when('type', {
      is: (type: FacilityType) => type !== FacilityType.Consumption,
      then: yup.object().when('type', {
        is: (type: FacilityType) => type === FacilityType.Production,
        then: yup.object().required(),
        otherwise: yup
          .object()
          .shape({
            agreement: yup
              .object()
              .nullable()
              .default(null)
              .test('', 'agreement-required', function () {
                const parent = this.parent as OwnershipStatus;
                return (
                  (parent.type === OwnershipStatusType.ThirdParty && parent.agreement !== null) ||
                  parent.type !== OwnershipStatusType.ThirdParty
                );
              }),
          })
          .required(),
      }),
      otherwise: yup.object().nullable().notRequired(),
    }),
});

export const Facilities = (props: Props) => {
  const [deleting, setDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [pressedSave, setPressedSave] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [keepDefaults, setKeepDefaults] = useState<boolean>(false);

  const [selectedFacility, setSelectedFacility] = useState<Facility | null>(null);

  const [render, setRender] = useReducer((x) => x + 1, 0);
  const containerRef = useRef<HTMLDivElement>(null);
  const mainListHeaderRef = useRef<HTMLDivElement>(null);
  const detailsListHeaderRef = useRef<HTMLDivElement>(null);

  const [mainListHeight, setMainListHeight] = useState<number>();
  const [detailsListHeader, setDetailsListHeader] = useState<number>();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (id !== 'new') {
        getFacility(id).ok((data) => setSelectedFacility(data));
      } else {
        setSelectedFacility({
          name: '',
          alias: '',
        } as Facility);
      }
    } else {
      setSelectedFacility(null);
    }
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', setRender);
    return () => window.removeEventListener('resize', setRender);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      if (mainListHeaderRef.current) {
        setMainListHeight(containerRef.current.getBoundingClientRect().height - mainListHeaderRef.current.offsetHeight);
      }

      if (detailsListHeaderRef.current) {
        setDetailsListHeader(
          containerRef.current.getBoundingClientRect().height - detailsListHeaderRef.current.getBoundingClientRect().height,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacility, render]);

  const gridSchema = props.selectedCategory === 'all' ? 'grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_80px]' : 'grid-cols-[2fr_1fr_1fr_1fr_1fr_80px]';

  return (
    <div ref={containerRef} className='w-full h-[calc(100vh-theme(spacing.20))] bg-zinc-50'>
      <Helmet title='Locations & Facilities' />
      {!selectedFacility ? (
        <>
          <div ref={mainListHeaderRef} className='h-20 flex items-center justify-between border-b border-zinc-200 bg-white px-6'>
            <div className='flex items-center gap-x-2 font-semibold text-lg'>
              <div>{props.title}</div>
              <div className='mt-0.5 flex items-center justify-center px-1 rounded-full bg-slate-100 text-brand text-xs'>
                {props.facilityData.totalResults}
              </div>
            </div>
            <div className='flex gap-x-3'>
              <div className='flex'>
                <AsyncFilters
                  owner={props.facilityData.searchParams.owner}
                  setOwner={(owner) =>
                    props.setFacilityData((current) => ({
                      ...current,
                      searchParams: {
                        ...current.searchParams,
                        owner: owner?.id,
                      },
                    }))
                  }
                  location={props.facilityData.searchParams.location}
                  setLocation={(location) =>
                    props.setFacilityData((current) => ({
                      ...current,
                      searchParams: {
                        ...current.searchParams,
                        location: location,
                      },
                    }))
                  }
                />
              </div>
              <div className='flex items-center relative'>
                <input
                  type='search'
                  className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                  autoFocus
                  placeholder='Find…'
                  value={props.facilityData.searchString}
                  onChange={(event) => props.setFacilityData((current) => ({ ...current, searchString: event.target.value }))}
                />
                <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
              </div>
              <div className='flex items-end justify-self-end'>
                <NavLink
                  to='../new-location'
                  className='flex align-middle self-center bg-brand rounded-full px-4 py-2 text-sm text-white font-semibold [&:active:not(:disabled)]:scale-95 disabled:cursor-wait whitespace-nowrap'
                >
                  New facility
                </NavLink>
              </div>
            </div>
          </div>

          {mainListHeight &&
            (props.loading ? (
              <SkeletonLoader height={mainListHeight} />
            ) : props.facilityData.list.length > 0 ? (
              <InfiniteScroll
                height={mainListHeight}
                dataLength={props.facilityData.list.length}
                next={props.fetchNextLocationsPage}
                hasMore={props.facilityData.nextPageToken !== ''}
                loader={
                  <div className='py-3 text-center'>
                    <FontAwesomeIcon size='2x' pulse icon={duotone('loader')} />
                  </div>
                }
              >
                <div
                  className={cn(gridSchema, 'grid items-center gap-x-2 sticky z-[1] top-0 bg-white border-b border-zinc-200 font-semibold')}
                >
                  <button
                    className='flex w-full justify-between items-center gap-2 pl-6 py-3 hover:bg-slate-50'
                    onClick={() =>
                      props.setFacilityData((current) => ({
                        ...current,
                        searchParams: {
                          ...current.searchParams,
                          sortAscending: !current.searchParams.sortAscending,
                        },
                      }))
                    }
                  >
                    <div>Name</div>
                    <FontAwesomeIcon
                      className={cn('text-brandDark', { 'rotate-180': props.facilityData.searchParams.sortAscending })}
                      icon={regular('arrow-up')}
                    />
                  </button>
                  <div>ID</div>
                  {props.selectedCategory === 'all' && <div className='whitespace-nowrap'>Type of location</div>}
                  <div>Owner</div>
                  <div>Country</div>
                  <div>Used in</div>
                  <div />
                </div>

                <div className='divide-y bg-white'>
                  {props.facilityData.list.map((facility, i) => {
                    const disabled = facility.usedInProducts === 0;
                    const onDeleteRequest = (close: () => void) => {
                      setDeleting(true);
                      deleteFacility(facility.id).call({
                        ok: (err) => {
                          if (!err) {
                            setDeleting(false);
                            close();
                            props.reload();
                          } else {
                            setHasError(true);
                            setDeleting(false);
                          }
                        },
                        fail: () => {
                          setDeleting(false);
                        },
                      });
                    };

                    return (
                      <div
                        key={i}
                        onClick={() => {
                          navigate(`/manage/locations/${props.selectedCategory}/${facility.id}`);
                        }}
                        className={cn(
                          gridSchema,
                          'grid items-center gap-x-2 text-zinc-500 py-3',
                          'hover:text-dark hover:cursor-pointer',
                          'hover:bg-slate-200 bg-white odd:bg-slate-50',
                        )}
                      >
                        <div title={facility.name} className='pl-6 truncate'>
                          <div className='flex items-center justify-between'>
                            <div className='truncate'>{facility.name}</div>
                            {facility.default && (
                              <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                                default
                              </div>
                            )}
                          </div>
                        </div>
                        <div title={facility.alias} className='truncate'>
                          {facility.alias}
                        </div>
                        {props.selectedCategory === 'all' && (
                          <div title={facilityOptions.find(({ id }) => id === facility.type)!.name} className='truncate'>
                            {facilityOptions.find(({ id }) => id === facility.type)!.name}
                          </div>
                        )}
                        <div title={facility.owner?.name} className='truncate'>
                          {facility.owner?.name}
                        </div>
                        <div title={facility.location.name} className='truncate'>
                          {facility.location.name}
                        </div>

                        <div onClick={(e) => e.stopPropagation()}>
                          <InfiniteScrollProductListModal
                            name={facility.name}
                            filter={{ facilityId: facility.id }}
                            usedInProducts={facility.usedInProducts}
                          >
                            <button
                              type='button'
                              disabled={disabled}
                              className={cn(
                                { 'cursor-not-allowed text-zinc-400': disabled },
                                'flex self-start items-center gap-x-1 bg-white',
                                'rounded-full border p-1 pr-2 [not:(:disabled)]:active:scale-95',
                              )}
                            >
                              <div
                                className={cn(
                                  { 'text-brand': !disabled },
                                  'flex items-center justify-center px-1',
                                  'h-4 text-xs bg-slate-100 rounded-full text-brand',
                                  facility.usedInProducts > 9 ? 'w-auto' : 'w-4',
                                )}
                              >
                                {facility.usedInProducts}
                              </div>
                              <div className={cn(disabled ? 'text-zinc-400' : 'text-zinc-500')}>Products</div>
                            </button>
                          </InfiniteScrollProductListModal>
                        </div>
                        <div className='pr-4'>
                          <div onClick={(event) => event.stopPropagation()} className='flex items-center justify-center gap-0.5'>
                            <button
                              type='button'
                              className='flex justify-center items-center w-8 px-1 h-8 aspect-square rounded-md hover:bg-white transition-colors'
                              onClick={() => {
                                navigate(`/manage/locations/${props.selectedCategory}/${facility.id}`);
                              }}
                            >
                              <FontAwesomeIcon icon={regular('edit')} />
                            </button>
                            <Modal
                              title={hasError ? `${facility.name} is currently in use` : `Deleting ${facility.name}`}
                              body={
                                hasError
                                  ? 'We’re sorry but deletion of this provider is disabled as it is either connected to an existing location/facility or being used in a product.'
                                  : 'Are you sure you want to remove this provider from your workspace? You will not be able to recover it.'
                              }
                              noSubmit={hasError}
                              confirmLabel='Delete'
                              waiting={deleting}
                              wrapperStyle='max-w-lg p-6'
                              onConfirm={(_, close) => onDeleteRequest(close)}
                              onCancel={hasError ? () => setHasError(false) : undefined}
                            >
                              <button
                                type='button'
                                className='flex justify-center items-center w-8 px-1 h-8 rounded-md hover:bg-white transition-colors'
                              >
                                <FontAwesomeIcon icon={regular('trash-can')} />
                              </button>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            ) : (
              <div className='flex flex-col items-center justify-center mt-20'>
                <div className='grid grid-rows-3 gap-y-3 items-center justify-items-center rounded-2xl border py-4 px-16 bg-white shadow'>
                  <div className='flex justify-center items-center size-10 bg-[#d6ff01] rounded-full'>
                    <FontAwesomeIcon className='text-2xl' icon={light('lightbulb')} />
                  </div>
                  <div>Start by creating a new facility.</div>
                  <NavLink to='../new-location' className='px-4 py-2 rounded-full bg-brand text-white'>
                    New facility
                  </NavLink>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className='grid grid-cols-[250px_auto] bg-gray-50'>
          <div>
            <div
              ref={detailsListHeaderRef}
              className='h-20 flex items-center justify-between border-b border-r border-zinc-200 bg-white px-6 uppercase text-zinc-500 whitespace-nowrap'
            >
              {props.title === 'locations' ? 'Locations & Facilities' : props.title}
            </div>
            <div className='flex flex-col divide-y bg-white border-r border-b border-zinc-200'>
              {detailsListHeader && (
                <InfiniteScroll
                  height={detailsListHeader}
                  next={props.fetchNextLocationsPage}
                  hasMore={props.facilityData.nextPageToken !== ''}
                  loader={<></>}
                  dataLength={props.facilityData.list.length}
                >
                  <button
                    className='flex w-full justify-between items-center gap-2 pl-6 p-3 hover:bg-slate-50'
                    onClick={() =>
                      props.setFacilityData((current) => ({
                        ...current,
                        searchParams: { ...current.searchParams, sortAscending: !current.searchParams.sortAscending },
                      }))
                    }
                  >
                    <div>Name</div>

                    <FontAwesomeIcon
                      className={cn('text-brandDark', { 'rotate-180': props.facilityData.searchParams.sortAscending })}
                      icon={regular('arrow-up')}
                    />
                  </button>
                  {props.facilityData.list.map((facility, i) => (
                    <NavLink
                      to={`../locations/${props.selectedCategory}/${facility.id}`}
                      className={cn(
                        'flex items-center gap-0 justify-between pl-6 p-3 hover:cursor-pointer',
                        selectedFacility?.id === facility.id
                          ? 'hover:bg-slate-200 bg-slate-200'
                          : 'hover:bg-slate-200 bg-white even:bg-slate-50',
                      )}
                      key={i}
                    >
                      <div title={facility.name} className={cn('truncate')}>
                        {facility.name}
                      </div>
                      {facility.default && (
                        <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                          default
                        </div>
                      )}
                    </NavLink>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>

          <div className='overflow-hidden'>
            <Formik<Facility>
              enableReinitialize
              initialValues={selectedFacility}
              validationSchema={facilityValidationSchema}
              validateOnBlur={pressedSave}
              validateOnChange={pressedSave}
              onSubmit={(values) => {
                setWaiting(true);
                (id === 'new' ? createFacility : updateFacility)({
                  ...values,
                  ownershipStatus: values.ownershipStatus ? { ...values.ownershipStatus, default: keepDefaults! } : undefined,
                }).call({
                  ok: (facility) => {
                    setSelectedFacility(facility);
                    setPressedSave(false);
                    setWaiting(false);
                  },
                });
                setWaiting(false);
              }}
            >
              <FacilityForm
                waiting={waiting}
                setWaiting={setWaiting}
                triedSubmit={pressedSave}
                setTriedSubmit={setPressedSave}
                keepDefaults={keepDefaults!}
                setPressedSave={setPressedSave}
                setKeepDefaults={setKeepDefaults}
                setSelectedFacility={setSelectedFacility}
                selectedCategory={props.selectedCategory}
              />
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

interface FacilityFormProps {
  triedSubmit: boolean;
  keepDefaults: boolean;
  waiting: boolean;
  setWaiting: Dispatch<SetStateAction<boolean>>;
  setTriedSubmit: Dispatch<SetStateAction<boolean>>;
  setKeepDefaults: Dispatch<SetStateAction<boolean>>;
  setPressedSave: Dispatch<SetStateAction<boolean>>;
  setSelectedFacility: Dispatch<SetStateAction<Facility | null>>;
  selectedCategory: string;
}

const FacilityForm = (props: FacilityFormProps) => {
  const formik = useFormikContext<Facility>();
  const navigate = useNavigate();
  const confirmationModalRef = useRef<ModalApi>(null);
  const unsavedChangesModal = useRef<ModalApi>(null);
  const unsavedChanges = !isEqual(formik.initialValues, formik.values);

  const blocker = useBlocker(() => unsavedChanges);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      unsavedChangesModal.current!.open();
    }
  }, [blocker.state]);

  return (
    <>
      <UnsavedChangesModalWarning blocker={blocker} ref={unsavedChangesModal} />
      <div className='h-20 flex justify-between gap-2 border-b border-zinc-200 px-6 bg-white'>
        <div className='flex items-center gap-2 overflow-hidden'>
          <button
            onClick={() => navigate(`/manage/locations/${props.selectedCategory}`)}
            className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
          >
            <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
          </button>
          <div className='flex gap-2 items-center truncate'>
            <div title={formik.values.name} className='text-xl font-semibold truncate'>
              {formik.values.name}
            </div>
            <StatusBadge state={props.waiting ? 'saving' : !isEqual(formik.initialValues, formik.values) ? 'unsaved' : undefined} />
          </div>
        </div>

        {!(isEqual(formik.initialValues, formik.values) || props.waiting) && (
          <div className='flex gap-3'>
            <button
              type='button'
              className='flex self-center border-2 border-[#220066] rounded-full px-4 py-1 text-[#220066] font-semibold active:scale-95'
              onClick={() => formik.setValues(formik.initialValues)}
            >
              Cancel
            </button>
            <div className='flex gap-2 items-center'>
              <button
                type='button'
                disabled={props.waiting}
                className={cn(
                  'self-center text-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
                  '[&:active:not(:disabled)]:scale-95',
                  'disabled:bg-transparent disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed',
                )}
                onClick={async () => {
                  props.setPressedSave(true);
                  await formik.validateForm();
                  if (await facilityValidationSchema.isValid(formik.values)) {
                    if (formik.values.ownershipStatus?.default) {
                      confirmationModalRef.current?.open();
                    } else {
                      props.setKeepDefaults(false);
                      formik.handleSubmit();
                    }
                  }
                }}
              >
                Save
              </button>
            </div>
            <ModalV3
              ref={confirmationModalRef}
              confirmLabel='Remove defaults'
              cancelLabel='Skip'
              onConfirm={() => {
                props.setKeepDefaults(false);
                formik.handleSubmit();
              }}
              onCancel={() => {
                props.setKeepDefaults(true);
                formik.handleSubmit();
              }}
              title='Just a final check before saving your settings.'
              body={
                <div className='flex flex-col gap-y-6'>
                  <div className='-mt-6 text-base'>
                    You have some default settings on this page. This means that we automatically assigned a value to these fields. If these
                    values are correct, we can remove the default tags or if you’re unsure, we can leave it all as is for now.
                  </div>
                  <div className='grid grid-cols-4'>
                    <div className='col-span-2 grid grid-cols-2 gap-y-3'>
                      {formik.values.ownershipStatus?.type && (
                        <div className='col-span-2 grid grid-cols-2'>
                          <div className='text-zinc-500'>Facility status</div>
                          <div className='flex items-center justify-between gap-x-2'>
                            <div>{formik.values.ownershipStatus?.name}</div>
                            <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                              default
                            </div>
                          </div>
                        </div>
                      )}
                      {formik.values.ownershipStatus?.agreement?.type && (
                        <div className='col-span-2 grid grid-cols-2'>
                          <div className='text-zinc-500'>Agreement type</div>
                          <div className='flex items-center justify-between gap-x-2'>
                            <div>{formik.values.ownershipStatus?.agreement?.name}</div>
                            <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                              default
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-span-2' />
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
      <EditFacilityForm />
    </>
  );
};
