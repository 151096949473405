import EventEmitter from 'eventemitter3';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import uniq from 'lodash/uniq';
import join from 'lodash/join';
import clone from 'lodash/clone';

export * from './customer';
export * from './dashboard';
export * from './import';
export * from './manufacturing';
export * from './model';
export * from './product';
export * from './productV3';
export * from './report';
export * from './setupV3';
export * from './shared';
export * from './user';
export * from './manage';

export const arrayToStringParam = (values: string[]) => join(uniq(clone(values)), '|');

export const buildQueryString = (payload: { [key: string]: string | number | boolean | undefined }) => {
  const query = Object.entries(payload)
    .filter(([, value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`)
    .join('&');
  return !isEmpty(query) ? `?${query}` : '';
};

const getOrigin = () =>
  process.env.REACT_APP_LOCAL_IMPACT_SERVICE
    ? 'http://localhost:8010'
    : `https://impact-api.${process.env.REACT_APP_ENV === 'dev' ? 'dev-euw1.' : ''}sustained.com`;

const getCommonHeaders = () => ({
  'X-Sustained-Internal-Usage': document.documentElement.hasAttribute('sustained-internal') ? 'true' : 'false',
  'X-Sustained-Request-Sid': `req.${uuidv4().replace(/-/g, '')}`,
  'X-Sustained-Client-Info': 'impact-web-app',
  // get auth from already existed session when start with local backend, this thing not working properly
  ...(() =>
    process.env.REACT_APP_LOCAL_IMPACT_SERVICE ? { 'X-Sustained-Authorization': `Bearer ${getAuthentication().token}` } : undefined)(),
});

// # Why are we using callbacks instead of promises?
// - With promises it's really annoying (or maybe even impossible) to wire up a sane, common way of handling errors and session timeouts.
// - With callbacks you can skip calling the "ok" callback and instead call some code to show an error dialog or log the user out.
// - I know… callbacks suck… but if you can figure out the same thing for promises — go ahead and refactor it :)
interface Callbacks<T> {
  ok: (data: T) => void;
  fail?: () => void;
}

export interface ApiRequest<T> {
  ok: (callback: (data: T) => void) => void;
  call: (callbacks: Callbacks<T>) => void;
}

interface AuthenticateResponse {
  user: {
    name: string;
  };
  token: string;
  errorCode?: AuthenticateError;
  message?: string;
}

export enum AuthenticateError {
  InvalidCredentials = 'invalid-credentials',
  UserUnconfirmed = 'user-unconfirmed',
  NewPasswordRequired = 'new-password-required',
  InvalidNewPassword = 'invalid-new-password', // assume to happen when temp password is same as new password. Most likely FE prevent such case
}

export enum NotFoundReason {
  User = 'user-not-found',
  Product = 'product-not-found',
  Model = 'model-not-found',
}

export enum ForbiddenReason {
  Workspace = 'workspace-access-forbidden',
}

export const getSelectedWorkspaceId = () => localStorage.getItem('workspaceId');
export const setSelectedWorkspaceId = (id: string) => localStorage.setItem('workspaceId', id);
export const hasAuthentication = () => !!localStorage.getItem('authentication');
export const getAuthentication = () => JSON.parse(localStorage.getItem('authentication')!) as AuthenticateResponse;
export const setAuthentication = (data: AuthenticateResponse) => localStorage.setItem('authentication', JSON.stringify(data));
export const clearAuthentication = () => localStorage.removeItem('authentication');
export const hasAuthenticationBypassParameters = () => !!getOriginParameter() && !!getCustomerSidParameter();
export const getMethodologyVersion = () => localStorage.getItem('methodologyVersion');

const getCustomerSidParameter = () => new URLSearchParams(window.location.search).get('customer');
const getOriginParameter = () => new URLSearchParams(window.location.search).get('origin');

export const authenticate = (payload: { username: string; password: string; newPassword?: string }) => ({
  call(ok: (data: AuthenticateResponse) => void, fail: () => void) {
    fetch(`${getOrigin()}/v3/authenticate`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        ...getCommonHeaders(),
      },
    })
      .then((response) => {
        if (!response.ok) {
          fail();
          events.emit('error');
          return;
        }

        return response.json().then((data) => {
          setSelectedWorkspaceId('');
          setAuthentication(data);
          ok(data);
        });
      })
      .catch(() => {
        events.emit('error');
      });
  },
});

export const request = <T>(
  method: string,
  path: string,
  params?: { search?: Record<string, any>; pdf?: string; csv?: string; body?: object; formData?: FormData },
): ApiRequest<T> => ({
  ok(callback: (data: T) => void) {
    this.call({ ok: callback });
  },

  call({ ok, fail = () => {} }: Callbacks<T>) {
    const safeOk = (data: T) => {
      try {
        ok(data);
      } catch (e) {
        console.error(e);
        safeFail();
      }
    };

    const safeFail = () => {
      try {
        fail();
      } catch (e) {
        console.error(e);
      }
    };

    const commonHeaders = getCommonHeaders();

    fetch(
      `${getOriginParameter() ?? getOrigin()}${path}${(() => {
        const search = new URLSearchParams(params?.search ?? {});
        Object.entries(params?.search ?? {}).forEach(([key, value]) => {
          search.set(key, value.toString());
        });
        return search.toString() ? `?${search.toString()}` : '';
      })()}`,
      {
        method,
        body: (() => {
          if (params?.body) {
            return JSON.stringify(params.body);
          }

          if (params?.formData) {
            return params.formData;
          }

          return undefined;
        })(),
        headers: {
          Accept: (() => {
            if (params?.csv) {
              return 'text/csv';
            }

            if (params?.pdf) {
              return 'application/pdf';
            }

            return 'application/json';
          })(),
          ...commonHeaders,
          ...(hasAuthentication() ? { Authorization: `Bearer ${getAuthentication().token}` } : {}),
          ...(getSelectedWorkspaceId() ? { 'X-Sustained-Impact-Workspace-Sid': getSelectedWorkspaceId()! } : {}),
          ...(getCustomerSidParameter() ? { 'X-Sustained-Impact-Customer-Sid': getCustomerSidParameter()! } : {}),
        },
      },
    )
      .then(async (response) => {
        if (response.status === 404) {
          safeFail();
          const json = await response.json();
          const requestSid = commonHeaders['X-Sustained-Request-Sid'];
          events.emit('not-found', { ...json, requestSid, responseCode: response.status });
          return;
        }
        if (response.status === 401) {
          clearAuthentication();
          safeFail();
          events.emit('unauthorized');
          return;
        }
        if (response.status === 403) {
          safeFail();
          const json = await response.json();
          const requestSid = commonHeaders['X-Sustained-Request-Sid'];
          events.emit('forbidden', { ...json, requestSid, responseCode: response.status });
          return;
        }

        if (!response.ok) {
          safeFail();

          if (process.env.REACT_APP_ENV === 'dev' || window.location.pathname.endsWith('/customers')) {
            response.json().then((data) => events.emit('error', data));
          } else {
            events.emit('error');
          }

          return;
        }

        if (params?.pdf || params?.csv) {
          return response.blob().then((blob) => {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = params.csv ? `${params.csv}.csv` : `${params.pdf}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            safeOk(undefined as any);
          });
        }

        const methodologyVersion = response.headers.get('x-sustained-methodology');
        methodologyVersion && localStorage.setItem('methodologyVersion', methodologyVersion);

        return response.json().then(safeOk);
      })
      .catch(() => {
        if (method === 'DELETE') {
          safeOk(undefined as any);
        } else {
          safeFail();
          events.emit('error');
        }
      });
  },
});

const events = new EventEmitter();

export interface ErrorPayload<T> {
  message: string;
  errorCode: T;
  requestSid: string;
  responseCode: number;
}

export const onUnauthorized = (listener: () => void) => events.on('unauthorized', listener);
export const onApiError = (listener: (payload?: object) => void) => events.on('error', listener);
export const onNotFound = (listener: (payload?: Promise<ErrorPayload<NotFoundReason>>) => void) => events.on('not-found', listener);
export const onForbidden = (listener: (payload?: Promise<ErrorPayload<NotFoundReason>>) => void) => events.on('forbidden', listener);

export const removeNotFoundListeners = () => {
  events.removeAllListeners('not-found');
};
export const removeUnauthorizedListeners = () => {
  events.removeAllListeners('unauthorized');
};
export const removeApiErrorListeners = () => {
  events.removeAllListeners('error');
};
export const removeForbiddenListeners = () => {
  events.removeAllListeners('forbidden');
};
