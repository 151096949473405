import { Fragment, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MultiRef from 'react-multi-ref';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

import { Ghg } from './sections/Ghg';
import { Overview as GhgOverview } from './sections/Ghg/Overview';
import { GhgPlaceholder } from './sections/Ghg/GhgPlaceholder';
import { GhgOverviewPlaceholder } from './sections/Ghg/OverviewPlaceholder';

import { Methodology, SectionId } from '../Prototype/types';
import { useApiQuery } from '../../../hooks/useApiQuery';
import {
  BaselineGhgReport,
  getGhgBaseline,
  getGhgHistoricalReport,
  getPefBaseline,
  getPefHistoricalReport,
  getPortfolioDashboard,
  Grade,
  HistoricalGhgReport,
  HistoricalPefReport,
  PortfolioDashboard,
  PortfolioState,
  ProductGeneral,
  ProductState,
  ProductType,
  searchProducts,
} from '../../../api';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../components/charts/shared';
import { DataCompletion } from './sections/Production/DataCompletion';
import { ProgressTrackingPlaceholder } from '../../../components/charts/ProgressTracking/ProgressTrackingPlaceholder';
import { OverviewPlaceholder } from './sections/Production/Overview/OverviewPlaceholder';
import { HistoricalOverview } from './sections/Production/Overview/HistoricalOverview';

import { ImpactDetails } from './sections/Production/ImpactDetails';
import { ImpactDetailsPlaceholder } from './sections/Production/ImpactDetails/Placeholder';
import { ProgressTracking } from './sections/Production/ProgressTracking';

import { WinnersLosersPerUnitOfProduct } from './sections/Production/WinnersLosersPerUnitOfProduct';
import { ProductionWinnersLosersAtVolumes } from './sections/Production/WinnersLosersAtVolumes';

import { EcoLabelingDetailsPlaceholder } from './sections/Consumer/EcolablingDetails/EcoLabelingDetailsPlaceholder';
import { Overview as EcoLabelingOverview } from './sections/Consumer/Overview';
import { EcoLabellingDetails } from './sections/Consumer/EcolablingDetails';
import { EcoLabelingOverviewPlaceholder } from './sections/Consumer/Overview/EcoLabelingOverviewPlaceholder';
import { useProgressChartEnabled } from '../../../hooks/useProgressChartEnabled';
import { ImpactValueType } from '../Products/Report/Sku/Overview';
import { BaselineOverview } from './sections/Production/Overview/BaselineOverview';

export const Dashboard = () => {
  const sectionRefs = useRef(new MultiRef<SectionId, HTMLDivElement>());

  const [methodology, setMethodology] = useState(Methodology.Production);

  const portfolioResponse = useApiQuery<PortfolioDashboard>(getPortfolioDashboard());
  const baselineResponse = useApiQuery(getPefBaseline());
  const isBaselineSet = useRef(false);

  const [latestHistoricalReportData, setLatestHistoricalReportData] = useState<HistoricalPefReport | undefined>(undefined);
  const [ghgReportData, setGhgReportData] = useState<BaselineGhgReport | HistoricalGhgReport>();

  const [products, setProducts] = useState<{ [key: string]: ProductGeneral[] }>();
  const [productsStats, setProductsStats] = useState<{ final: number; total: number; averageGrade: Grade }>();

  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string }>({ id: 'overall', name: 'Total Environmental Impact' });
  const [selectedType, setSelectedType] = useState<ImpactValueType>(ImpactValueType.Points);

  const enabled = useProgressChartEnabled({
    isBaselineSet: portfolioResponse.data?.tracking?.impacts[0].baseline !== undefined,
    data: portfolioResponse.data?.tracking?.impacts.map((impact) => ({
      id: impact.id,
      reports: impact.reports,
    })),
    selectedImpact,
  });

  useEffect(() => {
    if (portfolioResponse.data && portfolioResponse.data.state === PortfolioState.HistoricalCreated) {
      getPefHistoricalReport(portfolioResponse.data.historicalImpact.latestReportId).call({
        ok: (data) => setLatestHistoricalReportData(data),
      });
    }
  }, [portfolioResponse.data]);

  useEffect(() => {
    if (methodology === Methodology.Consumer && !products) {
      searchProducts({ pageSize: 1, contains: '', sortBy: 'totalImpact', state: ProductState.Complete, sortAscending: true }).call({
        ok: (data) =>
          searchProducts({
            pageSize: data.totalResults,
            contains: '',
            sortBy: 'totalImpact',
            state: ProductState.Complete,
            sortAscending: true,
          }).call({
            ok: (data) => {
              const completeProductsCount = data.products.length;
              const finalProducts = data.products.filter(
                ({ productType, totalGrade }) => productType === ProductType.Final && totalGrade !== '',
              );

              const sortedProducts: {
                [key in Grade]: ProductGeneral[];
              } = {
                [Grade.AA]: [],
                [Grade.A]: [],
                [Grade.B]: [],
                [Grade.C]: [],
                [Grade.D]: [],
                [Grade.E]: [],
                [Grade.F]: [],
                [Grade.G]: [],
              };

              finalProducts
                .sort((a, b) => a.totalGrade.localeCompare(b.totalGrade))
                .forEach((product) => {
                  sortedProducts[product.totalGrade as Grade].push(product);
                });

              setProductsStats({
                final: finalProducts.length,
                total: completeProductsCount,
                averageGrade: (() => {
                  // slice needed to exclude FE grade
                  const grades: Grade[] = Array.from(Object.values(Grade)).slice(1, Object.values(Grade).length);

                  const { totalWeightedGrade, totalQuantity } = Object.entries(sortedProducts)
                    .map(([grade, products]) => ({
                      numericGrade: grades.indexOf(grade as Grade),
                      quantity: products.length,
                    }))
                    .reduce(
                      (acc, item) => {
                        acc.totalWeightedGrade += item.numericGrade * item.quantity;
                        acc.totalQuantity += item.quantity;
                        return acc;
                      },
                      { totalWeightedGrade: 0, totalQuantity: 0 },
                    );

                  return grades
                    .map((grade, index) => ({ grade, difference: Math.abs(totalWeightedGrade / totalQuantity - index) }))
                    .reduce((acc, item) => (item.difference < acc.difference ? item : acc), { grade: '', difference: Infinity })
                    .grade as Grade;
                })(),
              });

              setProducts(sortedProducts);
            },
          }),
      });
    }

    if (methodology === Methodology.Ghg && !ghgReportData) {
      if (portfolioResponse.data?.state === PortfolioState.BaselineSet) {
        getGhgBaseline().call({
          ok: (data) => setGhgReportData(data),
        });
      } else if (portfolioResponse.data?.state === PortfolioState.HistoricalCreated) {
        getGhgHistoricalReport(portfolioResponse.data.historicalImpact.latestReportId).call({
          ok: (data) => setGhgReportData(data),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodology]);

  if (!portfolioResponse.data || !baselineResponse.data) {
    return <></>;
  }

  if (!baselineResponse.data.errorCode) {
    isBaselineSet.current = true;
  }

  const headerProps = {
    methodology: methodology,
    portfolio: portfolioResponse.data,
    selectedImpact,
    selectedType,
  };
  const sectionProps = {
    methodology,
    portfolio: portfolioResponse.data,
    selectedImpact,
    selectedType,
  };

  const sections = (() => {
    if (methodology === Methodology.Production) {
      switch (portfolioResponse.data?.state) {
        case PortfolioState.Empty:
          return [
            {
              id: SectionId.WinnersLosers,
              label: 'Winners & losers',
              component: (
                <WinnersLosersPerUnitOfProduct
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.WinnersLosers)}
                  title='winners & losers per unit of product'
                  products={undefined}
                />
              ),
              disabled: true,
            },
            {
              id: SectionId.DataCompletion,
              label: 'Data completion',
              component: (
                <DataCompletion
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.DataCompletion)}
                  title='Data completion'
                  portfolio={portfolioResponse.data}
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <OverviewPlaceholder title='Overview' ref={sectionRefs.current.ref(SectionId.Overview)} />,
              disabled: true,
            },
            {
              id: SectionId.ProgressTracking,
              label: 'Progress tracking',
              component: (
                <ProgressTrackingPlaceholder
                  attention={`We couldn't find any data for this section. Set a baseline and upload historical data to start tracking progress against your targets.`}
                  title='Progress tracking'
                  ref={sectionRefs.current.ref(SectionId.ProgressTracking)}
                />
              ),
              disabled: true,
            },
            {
              id: SectionId.ImpactDetails,
              label: 'Impact details',
              component: <ImpactDetailsPlaceholder title='Impact details' ref={sectionRefs.current.ref(SectionId.ImpactDetails)} />,
              disabled: true,
            },
          ];
        case PortfolioState.BaselineNotSet:
          return [
            {
              id: SectionId.WinnersLosers,
              label: 'Winners & losers',
              component: (() => {
                return (
                  <WinnersLosersPerUnitOfProduct
                    {...sectionProps}
                    ref={sectionRefs.current.ref(SectionId.WinnersLosers)}
                    title='winners & losers per unit of product'
                    products={portfolioResponse.data.productsImpact}
                  />
                );
              })(),
              disabled: false,
            },
            {
              id: SectionId.DataCompletion,
              label: 'Data completion',
              component: (
                <DataCompletion
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.DataCompletion)}
                  title='Data completion on average'
                  portfolio={portfolioResponse.data}
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <OverviewPlaceholder title='overview' ref={sectionRefs.current.ref(SectionId.Overview)} />,
              disabled: true,
            },
            {
              id: SectionId.ImpactDetails,
              label: 'Impact details',
              component: <ImpactDetailsPlaceholder title='Impact details' ref={sectionRefs.current.ref(SectionId.ImpactDetails)} />,
              disabled: true,
            },
            {
              id: SectionId.ProgressTracking,
              label: 'Progress tracking',
              component: (
                <ProgressTrackingPlaceholder
                  title='Progress tracking'
                  ref={sectionRefs.current.ref(SectionId.ProgressTracking)}
                  attention={`We couldn't find any data for this section. Set a baseline and upload historical data to start tracking progress against your targets.`}
                />
              ),
              disabled: true,
            },
          ];
        case PortfolioState.BaselineSet || PortfolioState.HistoricalCreated:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: (
                <BaselineOverview
                  {...sectionProps}
                  title='Overview'
                  ref={sectionRefs.current.ref(SectionId.Overview)}
                  baseline={baselineResponse.data}
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.ImpactDetails,
              label: 'Impact details',
              component: (
                <ImpactDetails
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.ImpactDetails)}
                  title='Impact details'
                  impactUnit={baselineResponse?.data?.analysis.impacts.find(({ id }) => id === selectedImpact.id)?.unit ?? 'impact p.'}
                  timeframe={
                    baselineResponse.data
                      ? {
                          start: new Date(baselineResponse.data.startDate),
                          end: new Date(baselineResponse.data.endDate),
                        }
                      : undefined
                  }
                  radarData={
                    baselineResponse.data
                      ? baselineResponse.data?.analysis.impactStageMatrix.map((impact) => ({
                          ...impact,
                          name: impact.impactName,
                          keyName: 'Impact',
                          isMajor: impact.isMajorImpact,
                        }))
                      : undefined
                  }
                  pieData={
                    selectedImpact.id === 'overall'
                      ? baselineResponse.data?.analysis.lifecycleStageImpacts.map((impact) => ({
                          ...impact,
                          isMajor: impact.isMajorImpact,
                          value: impact.absImpactSharePercent,
                          bgColor: setColor({ key: impact.name }, lifeCycleStagesColorHexSchema),
                        }))
                      : baselineResponse.data?.analysis.impactStageMatrix
                          .find((impact) => impact.impactId === selectedImpact.id)
                          ?.stages.map((stage) => ({
                            ...stage,
                            isMajor: stage.isMajorStage,
                            value: stage.absSharePercent,
                            bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema),
                          }))
                  }
                  baseline={baselineResponse.data}
                />
              ),
              disabled: false,
            },
            ...(() =>
              selectedType === 'impactPoints'
                ? [
                    {
                      id: SectionId.WinnersLosers,
                      label: 'Winners & losers',
                      component: (() => {
                        return (
                          <ProductionWinnersLosersAtVolumes
                            {...sectionProps}
                            ref={sectionRefs.current.ref(SectionId.WinnersLosers)}
                            title='winners & losers at volumes'
                            data={{
                              timeframe: {
                                start: baselineResponse.data.startDate,
                                end: baselineResponse.data.endDate,
                              },
                              products: {
                                highestImpactProducts: baselineResponse.data?.analysis.highestImpactProducts,
                                lowestImpactProducts: baselineResponse.data?.analysis.lowestImpactProducts,
                              },
                              productsRefs: baselineResponse.data?.products,
                            }}
                          />
                        );
                      })(),
                      disabled: false,
                    },
                  ]
                : [])(),
            {
              id: SectionId.DataCompletion,
              label: 'Data completion',
              component: (
                <DataCompletion
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.DataCompletion)}
                  title='Data completion'
                  portfolio={portfolioResponse.data}
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.ProgressTracking,
              label: 'Progress tracking',
              component: (
                <ProgressTrackingPlaceholder
                  title='Progress tracking'
                  ref={sectionRefs.current.ref(SectionId.ProgressTracking)}
                  attention={`We couldn't find any data for this section. Set a baseline and upload historical data to start tracking progress against your targets.`}
                />
              ),
              disabled: true,
            },
          ];
        case PortfolioState.HistoricalCreated:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: (
                <HistoricalOverview
                  {...sectionProps}
                  title='Overview'
                  ref={sectionRefs.current.ref(SectionId.Overview)}
                  historical={latestHistoricalReportData}
                  baseline={baselineResponse.data}
                  tracking={portfolioResponse.data.tracking}
                />
              ),
              disabled: false,
            },
            ...(() => {
              return enabled
                ? [
                    {
                      id: SectionId.ProgressTracking,
                      label: 'Progress tracking',
                      component: (
                        <ProgressTracking
                          {...sectionProps}
                          ref={sectionRefs.current.ref(SectionId.ProgressTracking)}
                          title='Progress tracking'
                          portfolio={portfolioResponse.data}
                        />
                      ),
                      disabled: false,
                    },
                  ]
                : [];
            })(),
            {
              id: SectionId.ImpactDetails,
              label: 'Impact details',
              component: (
                <ImpactDetails
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.ImpactDetails)}
                  title='Impact details'
                  impactUnit={latestHistoricalReportData?.analysis.impacts.find(({ id }) => id === selectedImpact.id)?.unit ?? 'impact p.'}
                  timeframe={
                    latestHistoricalReportData
                      ? {
                          start: new Date(latestHistoricalReportData?.startDate),
                          end: new Date(latestHistoricalReportData?.endDate),
                        }
                      : undefined
                  }
                  radarData={latestHistoricalReportData?.analysis.impactStageMatrix.map((impact) => ({
                    ...impact,
                    name: impact.impactName,
                    keyName: 'Impact',
                    isMajor: impact.isMajorImpact,
                  }))}
                  pieData={
                    selectedImpact.id === 'overall'
                      ? latestHistoricalReportData?.analysis.lifecycleStageImpacts.map((impact) => ({
                          ...impact,
                          isMajor: impact.isMajorImpact,
                          value: impact.absImpactSharePercent,
                          bgColor: setColor({ key: impact.name }, lifeCycleStagesColorHexSchema),
                        }))
                      : latestHistoricalReportData?.analysis.impactStageMatrix
                          .find((impact) => impact.impactId === selectedImpact.id)
                          ?.stages.map((stage) => ({
                            ...stage,
                            isMajor: stage.isMajorStage,
                            value: stage.absSharePercent,
                            bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema),
                          }))
                  }
                  historical={latestHistoricalReportData}
                />
              ),
              disabled: false,
            },
            ...(() =>
              selectedType === 'impactPoints'
                ? [
                    {
                      id: SectionId.WinnersLosers,
                      label: 'Winners & losers',
                      component: (() => {
                        return (
                          <ProductionWinnersLosersAtVolumes
                            {...sectionProps}
                            ref={sectionRefs.current.ref(SectionId.WinnersLosers)}
                            title='winners & losers at volumes'
                            data={
                              latestHistoricalReportData
                                ? {
                                    timeframe: {
                                      start: latestHistoricalReportData.startDate,
                                      end: latestHistoricalReportData.endDate,
                                    },
                                    products: {
                                      highestImpactProducts: latestHistoricalReportData.analysis.highestImpactProducts,
                                      lowestImpactProducts: latestHistoricalReportData.analysis.lowestImpactProducts,
                                    },
                                    productsRefs: latestHistoricalReportData.products,
                                  }
                                : undefined
                            }
                          />
                        );
                      })(),
                      disabled: false,
                    },
                  ]
                : [])(),
            {
              id: SectionId.DataCompletion,
              label: 'Data completion',
              component: (
                <DataCompletion
                  {...sectionProps}
                  title='Data completion on average'
                  portfolio={portfolioResponse.data}
                  ref={sectionRefs.current.ref(SectionId.DataCompletion)}
                />
              ),
              disabled: false,
            },
            ...(() => {
              return enabled
                ? []
                : [
                    {
                      id: SectionId.ProgressTracking,
                      label: 'Progress tracking',
                      component: (
                        <ProgressTrackingPlaceholder
                          title='Progress tracking'
                          ref={sectionRefs.current.ref(SectionId.ProgressTracking)}
                          attention='This graph shows progress throughout the years. In order to view it, the baseline needs to be created using one year (either a calendar or tax year) as time frame not custom dates. Uploaded historical data should cover full months as well.'
                        />
                      ),
                      disabled: true,
                    },
                  ];
            })(),
          ];
      }
    } else if (methodology === Methodology.Consumer) {
      switch (portfolioResponse.data?.state) {
        case PortfolioState.Empty:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <EcoLabelingOverviewPlaceholder title='Overview' />,
              disabled: true,
            },
            {
              id: SectionId.EcoLabellingDetails,
              label: 'Eco-labelling details',
              component: (
                <EcoLabelingDetailsPlaceholder
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.EcoLabellingDetails)}
                  title='Eco-labelling details (For 1 kg of product)'
                />
              ),
              disabled: true,
            },
          ];
        case PortfolioState.BaselineNotSet:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <EcoLabelingOverview productsStats={productsStats} title='Overview' />,
              disabled: false,
            },
            {
              id: SectionId.EcoLabellingDetails,
              label: 'Eco-labelling details',
              component: (
                <EcoLabellingDetails
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.EcoLabellingDetails)}
                  title='Eco-labelling details (For 1 kg of product)'
                  products={products}
                />
              ),
              disabled: true,
            },
          ];
        case PortfolioState.BaselineSet:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <EcoLabelingOverview productsStats={productsStats} title='Overview' />,
              disabled: false,
            },
            {
              id: SectionId.EcoLabellingDetails,
              label: 'Eco-labelling details',
              component: (
                <EcoLabellingDetails
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.EcoLabellingDetails)}
                  title='Eco-labelling details (For 1 kg of product)'
                  products={products}
                />
              ),
              disabled: false,
            },
          ];
        case PortfolioState.HistoricalCreated:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <EcoLabelingOverview productsStats={productsStats} title='Overview' />,
              disabled: false,
            },
            {
              id: SectionId.EcoLabellingDetails,
              label: 'Eco-labelling details',
              component: (
                <EcoLabellingDetails
                  {...sectionProps}
                  ref={sectionRefs.current.ref(SectionId.EcoLabellingDetails)}
                  title='Eco-labelling details (For 1 kg of product)'
                  products={products}
                />
              ),
              disabled: false,
            },
          ];
        default:
          return [];
      }
    } else if (methodology === Methodology.Ghg) {
      switch (portfolioResponse.data?.state) {
        case PortfolioState.Empty:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <GhgOverviewPlaceholder title='Overview' />,
              disabled: true,
            },
            {
              id: SectionId.Ghg,
              label: 'Impact details',
              component: <GhgPlaceholder title='Impact details' />,
              disabled: true,
            },
          ];
        case PortfolioState.BaselineNotSet:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: <GhgOverviewPlaceholder title='Overview' />,
              disabled: true,
            },
            {
              id: SectionId.Ghg,
              label: 'Impact details',
              component: <GhgPlaceholder title='Impact details' />,
              disabled: true,
            },
          ];
        case PortfolioState.BaselineSet:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: (
                <GhgOverview
                  timeframe={{
                    start: new Date(baselineResponse.data.startDate),
                    end: new Date(baselineResponse.data.endDate),
                  }}
                  data={ghgReportData}
                  title='Overview'
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.Ghg,
              label: 'Impact details',
              component: (
                <Ghg
                  timeframe={{
                    start: new Date(baselineResponse.data.startDate),
                    end: new Date(baselineResponse.data.endDate),
                  }}
                  data={ghgReportData}
                  title='Impact details'
                />
              ),
              disabled: false,
            },
          ];
        case PortfolioState.HistoricalCreated:
          return [
            {
              id: SectionId.Overview,
              label: 'Overview',
              component: (
                <GhgOverview
                  timeframe={{
                    start: new Date(portfolioResponse.data.historicalImpact.startDate),
                    end: new Date(portfolioResponse.data.historicalImpact.endDate),
                  }}
                  data={ghgReportData}
                  title='Overview'
                />
              ),
              disabled: false,
            },
            {
              id: SectionId.Ghg,
              label: 'Impact details',
              component: (
                <Ghg
                  timeframe={{
                    start: new Date(portfolioResponse.data.historicalImpact.startDate),
                    end: new Date(portfolioResponse.data.historicalImpact.endDate),
                  }}
                  data={ghgReportData}
                  title='Impact details'
                />
              ),
              disabled: false,
            },
          ];
      }
    } else {
      return [];
    }
  })();

  return (
    <div className='flex flex-col justify-items-center leading-none text-sm'>
      <Helmet title='Dashboard' />
      <Header
        {...headerProps}
        selectedImpact={selectedImpact}
        setSelectedImpact={setSelectedImpact}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        baseline={baselineResponse.data}
        targets={latestHistoricalReportData?.tracking?.impacts?.map((impact) => ({
          impactId: impact.id,
          target: impact.target,
        }))}
      />
      <div className='flex'>
        <Sidebar
          methodology={methodology}
          setMethodology={setMethodology}
          sections={sections}
          sectionRefs={sectionRefs.current.map}
          portfolio={portfolioResponse.data}
        />
        <div className='flex-1 flex flex-col mb-[calc(25vw)]'>
          <div className='flex flex-col gap-10 px-5 py-6'>
            {sections.map(({ id, component }) => (
              <Fragment key={id}>{component}</Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
