import { Fragment, ReactNode, RefObject, SetStateAction, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { PortfolioJourney, ProductStage, ProductState, createProductModelV3 } from '../../../api';
import { LimitTooltip } from '../../../components/LimitTooltip';
import { createProduct } from '../Products/Details/dataModel';
import { simplify, roundToLong } from '../shared';
import { useProfile } from '../../../hooks/useProfile';
import { Buttons } from './Buttons';
import { AppRoutes } from '../index';
import cn from 'classnames';

interface Props {
  data: Partial<PortfolioJourney>;
  targetWidgetRef: RefObject<HTMLDivElement>;
  selectedStep: string;
  setSelectedStep: (v: SetStateAction<string>) => void;
}

export const CreationFlow = (props: Props) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const [modelCreating, setModelCreating] = useState(false);
  const [productCreateLimit, setProductCreateLimit] = useState(false);
  const [productCreating, setProductCreating] = useState(false);

  const createNewProduct = () => {
    setProductCreating(true);
    createProduct().call({
      ok: ({ id, errorCode }) => {
        setProductCreating(false);
        if (errorCode) {
          setProductCreateLimit(true);
        } else {
          navigate(`/products/${id}/info?stage=${ProductStage.Development}`);
        }
      },
    });
  };

  const wrapper = (subcategory: string, title: string, body: ReactNode) => {
    return (
      <div className='flex flex-col gap-3 my-3 text-[92%]'>
        <div className='text-sm font-semibold uppercase text-zinc-500'>{subcategory}</div>
        <div className='text-2xl'>{title}</div>
        {body}
      </div>
    );
  };

  return (
    <>
      {(() => {
        switch (props.selectedStep) {
          case '1':
            const productsInDevelopment = props.data.inDevelopment?.total;
            return productsInDevelopment === 0
              ? wrapper(
                  'Create a new product concept',
                  'Start by creating a new product',
                  <Fragment>
                    <div>
                      What is the impact of all your new product development (NPD) work? Create your initial product concepts as ‘in
                      development’ products in the system to identify any potential impact reductions early on in the process, even before
                      they get commercialised.
                    </div>
                    <LimitTooltip
                      placement='top-start'
                      enabled={productCreateLimit}
                      entityName='products limit'
                      valueKey='maxProductSkuCount'
                      onDismiss={() => setProductCreateLimit(false)}
                    >
                      <div>
                        <Buttons
                          primary={{
                            waiting: productCreating,
                            disabled: !profile.selectedWorkspace.permissions.productManagement,
                            readOnlyWarning: !profile.selectedWorkspace.permissions.productManagement,
                            label: 'New in development product',
                            onClick: () => createNewProduct(),
                          }}
                          secondary={{
                            label: (
                              <div className='flex items-center gap-2'>
                                <div className='h-6 aspect-square flex items-center justify-center bg-slate-100 rounded-full text-[85%]'>
                                  {props.data.inDevelopment?.drafts}
                                </div>
                                <div>Drafts available</div>
                              </div>
                            ),
                            onClick: () => navigate(`/products?state=${ProductState.Draft}&stage=${ProductStage.Development}`),
                          }}
                        />
                      </div>
                    </LimitTooltip>
                  </Fragment>,
                )
              : wrapper(
                  'Create a new product concept',
                  `You have ${productsInDevelopment} products in development`,
                  <Fragment>
                    <div>
                      Understand the impact of all your new product development (NPD) work by creating your initial product concepts as ‘in
                      development’ products in the system. This will help you identify any potential impact reductions early on in the
                      process, even before they get commercialised, to ensure you try to minimise environmental impact as much as possible.
                    </div>
                    <LimitTooltip
                      placement='top-start'
                      enabled={productCreateLimit}
                      entityName='products limit'
                      valueKey='maxProductSkuCount'
                      onDismiss={() => setProductCreateLimit(false)}
                    >
                      <div>
                        <Buttons
                          primary={{
                            disabled: !profile.selectedWorkspace.permissions.productManagement,
                            readOnlyWarning: !profile.selectedWorkspace.permissions.productManagement,
                            label: 'New in development product',
                            onClick: () => createNewProduct(),
                          }}
                          secondary={{
                            label: (
                              <div className='flex items-center gap-2'>
                                <div className='h-6 aspect-square flex items-center justify-center bg-slate-100 rounded-full text-[85%]'>
                                  {props.data.inDevelopment?.drafts}
                                </div>
                                <div>Drafts available</div>
                              </div>
                            ),
                            onClick: () => navigate(`/products?state=${ProductState.Draft}&stage=${ProductStage.Development}`),
                          }}
                        />
                      </div>
                    </LimitTooltip>
                  </Fragment>,
                );

          case '2.1':
            return props.data.targets && props.data.targets.totalTargets > 0
              ? wrapper(
                  'Set targets',
                  `${props.data.targets.totalTargets} target${props.data.targets.totalTargets === 1 ? '' : 's'} set`,
                  <Fragment>
                    <div>
                      Any target you set will help you track your progress against a specific goal, whether it’s a target for your overall
                      environmental impact, or any underlying impact categories such as climate change or water deprivation. These targets
                      are common to the whole workspace, to help both people working on New Product Development (NPD) and those working on
                      improving existing products to work off a common goal.
                    </div>
                    <Buttons
                      primary={{
                        label: 'Set new targets',
                        onClick: () => navigate('/manufacturing/targets'),
                      }}
                      secondary={{
                        label: 'View targets',
                        onClick: () => props.targetWidgetRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' }),
                      }}
                    />
                  </Fragment>,
                )
              : wrapper(
                  'Set targets',
                  'You haven’t set targets yet',
                  <Fragment>
                    <div>
                      Targets are a useful means to ensure you all work towards a common goal, both when it comes to New Product Development
                      (NPD) and the improvement of current product lines. You’ll be able to visualise how new products currently being
                      developed would contribute to achieving those goals through forecasting later on in your journey.
                    </div>
                    <Buttons
                      primary={{
                        label: 'Set new targets',
                        onClick: () => navigate('/manufacturing/targets'),
                      }}
                      secondary={{
                        label: 'Skip this step',
                        onClick: () => props.setSelectedStep('3'),
                      }}
                    />
                  </Fragment>,
                );

          case '2.2':
            return props.data.inDevelopment && props.data.inDevelopment.total > 0
              ? wrapper(
                  'Identify reduction opportunities',
                  'Improve your highest impact products',
                  <Fragment>
                    <div>
                      Below are your highest impact products amongst the new ones you’re currently developing. Try our modelling tool to see
                      if you can identify impact reduction opportunities in the way you’re considering making them.
                    </div>
                    <LimitTooltip
                      placement='top-start'
                      enabled={modelsCreateLimit}
                      entityName='models limit'
                      valueKey='maxProductModelCount'
                      onDismiss={() => setModelsCreateLimit(false)}
                    >
                      <div />
                    </LimitTooltip>
                    {props.data.inDevelopment.highestImpactProducts.length > 0 && (
                      <div className='flex gap-3'>
                        {props.data.inDevelopment.highestImpactProducts.map((product) => (
                          <div className={cn('w-48 flex flex-col justify-self-center gap-4 border rounded-lg p-3')} key={product.id}>
                            <div title={product.name} className='font-semibold truncate'>
                              {product.name}
                            </div>
                            <div className='flex gap-2 items-center'>
                              <div title={roundToLong(product.impactPoints)} className='text-lg text-red-500 font-semibold'>
                                {simplify(product.impactPoints)}
                              </div>
                              <div className='text-xs uppercase'>impact points</div>
                            </div>
                            <button
                              disabled={modelCreating}
                              onClick={() => {
                                setModelCreating(true);
                                createProductModelV3(product.id).call({
                                  ok: ({ model, errorCode }) => {
                                    setModelCreating(false);
                                    if (errorCode) {
                                      setModelsCreateLimit(true);
                                    } else {
                                      navigate(`/products/${product.id}/models/${model.id}`);
                                    }
                                  },
                                });
                              }}
                              className='flex self-start px-3 py-1.5 bg-slate-200 font-semibold text-[#4a2e82] rounded-lg shadow-sm mt-6'
                            >
                              Start modelling
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    <Buttons
                      primary={{
                        label: 'See more',
                        onClick: () => navigate(`/products?stage=${ProductStage.Development}&sortAscending=false&sortBy=totalImpact`),
                      }}
                    />
                  </Fragment>,
                )
              : wrapper(
                  'Identify reduction opportunities',
                  'Improve your highest impact products',
                  <Fragment>
                    <div>
                      To identify impact reduction opportunities you first need to create a new product as ‘in development’. You can then
                      try our modelling tool to see if you can identify impact reduction opportunities in the way you’re considering making
                      them.
                    </div>
                    <div className='flex gap-4'>
                      {['#1 Most impactful product', '#2 Most impactful product', '#3 Most impactful product'].map((item, i) => (
                        <div key={i} className='p-4 w-56 aspect-square border rounded-xl shadow-sm'>
                          <div className='flex items-center justify-center text-center text-lg h-full w-full bg-zinc-100 rounded-xl text-zinc-400'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Fragment>,
                );

          case '2.3':
            return wrapper(
              'Forecast reduction at scale',
              'Create a new volume forecast',
              <Fragment>
                <div>
                  Forecasting your impact at scale is important to understand what could happen if you implemented a change across your
                  entire production. Select the products and/or models you would like to include in the volume forecast, specify how many
                  units you’d be producing of each over the selected timeframe and see the resulting impact at scale.
                </div>
                <NavLink
                  to={AppRoutes().report.newForecast}
                  className='mt-3 flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                >
                  Create a forecast
                </NavLink>
              </Fragment>,
            );

          case '3':
            return wrapper(
              'Start production',
              'Time to commercialise new products!',
              <Fragment>
                <div>
                  Before going ahead with commercialisation, we suggest forecasting the impact at scale of starting the production of new
                  products, to better understand how it will affect your overall impact.
                </div>
                <div className='font-semibold'>
                  After your products start production, remember to change their status from in development to in production.
                </div>
                <div className='flex items-center gap-4 mt-3'>
                  <NavLink
                    to={AppRoutes().report.newForecast}
                    className='flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                  >
                    Volume forecast
                  </NavLink>
                  <NavLink
                    to={`/products?stage=${ProductStage.Development}`}
                    className='rounded-lg px-3 py-1 font-semibold border border-[#4a2e82] text-[#4a2e82]'
                  >
                    Products list
                  </NavLink>
                </div>
              </Fragment>,
            );

          case '4':
            return wrapper(
              'Repeat the process',
              'This is an iterative process.',
              <Fragment>
                <div>
                  Sustainability is a journey and as such it is all about continuous improvements. You’re working on some new products
                  recipes? Add them to the system and try to design them with the lowest environmental impact possible. This is much better
                  than having to come back to the drawing board later to reduce that impact because you are asked to. Set yourself up for
                  long term success!
                </div>
                <Buttons
                  primary={{
                    label: 'Back to start',
                    onClick: () => props.setSelectedStep('1'),
                  }}
                />
              </Fragment>,
            );
        }
      })()}
    </>
  );
};
